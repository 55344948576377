export const NavbarMenu = [
 
    {
      "id": 1,
      "name": "Home",
      "url": "/"
    },
    {
      "id": 2,
      "name": "About",
      "url": "/about"
    },
    {
      "id": 3,
      "name": "Products",
      "url": "/products"
    },
    {
      "id": 4,
      "name": "Courses",
      "url": "/courses/101"
    }
  
  ]