import CourseDesc from '@/components/CourseDesc/CourseDesc'
import React from 'react'


const CourseDescPage = () => {
  return (
    <div className='margin'>
      <CourseDesc/>
 
    </div>
  )
}

export default CourseDescPage