
import CallSection from '@/components/CallSection/CallSection'
import React from 'react'

const CallPage = () => {
  return (
    <div className='margin'>
     <CallSection/>
    </div>
  )
}

export default CallPage