import PaymentSection from '@/components/PaymentSection/PaymentSection'
import React from 'react'

const PaymentPage = () => {
  return (
    <div>
      <PaymentSection/>
    </div>
  )
}

export default PaymentPage