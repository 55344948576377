import React from 'react'
// import {TheosPlayer} from "@aka_theos/react-hls-player";
import "plyr-react/plyr.css"
import HLS from './HLS'
import Component from './testHLS'


const Test = () => {
 
  return (
    <div style={{ padding: '20px' }} className='margin'>
     <Component/>
  </div>
  )
}

export default Test